<template>
  <div class="row">
    <div class="col-12">

      <!-- Filter -->
      <div class="accordion" id="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters"
                    aria-expanded="false" aria-controls="filters">
              {{ $t('events.filters.title') }} ({{ $t('events.filters.click_to_open') }})
            </button>
          </h2>
          <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne"
               data-bs-parent="#filterAccordion">
            <div class="accordion-body">
              <Form @submit="(values) => loadSites(pagination.page, values)" :validation-schema="schema" ref="form">
                <div class="row">
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="vehicleRegistrationFilter" class="form-label">{{ $t('sites.name') }}</label>
                    <Field type="text" name="name" class="form-control" id="siteNameFilter" placeholder="Enter name"/>
                    <ErrorMessage name="name" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="vehicleRegistrationFilter" class="form-label">{{ $t('sites.postcode') }}</label>
                    <Field type="text" name="postcode" class="form-control" id="sitePostcodeFilter" placeholder="Enter postcode"/>
                    <ErrorMessage name="postcode" class="error-feedback"/>
                  </div>
                </div>
                <div class="row m-t-sm">
                  <div class="col-12" style="width:auto;">
                    <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                      <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                      <span>{{ $t('events.filters.search') }}</span>
                    </button>
                  </div>

                  <!-- Clear filter -->
                  <div class="col-12" style="width:auto;">
                    <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="(values) => clearFilter(pagination.page, values)">
                      <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                      <span>{{ $t('global.clear_filter') }}</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Table Heading -->
  <h1 class="table-heading m-b-xs"> {{ $t('navigation.side.sites') }} </h1>

  <!-- Pagination -->
  <Pagination v-show="!loading" :config="pagination" @search="(page) => loadSites(page, currentParams)"/>

  <!-- Loading -->
  <div v-show="loading" style="width: 100%; text-align: center;">
    <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
      <span class="visually-hidden">{{ $t('events.loading') }}...</span>
    </div>
  </div>

  <!-- Sites -->
  <table v-show="!loading" class="table table-borderless">
    <thead>
      <tr>
        <th scope="col"> {{ $t('sites.name').toUpperCase() }}</th>
        <th scope="col"> {{ $t('sites.reference').toUpperCase() }}</th>
        <th scope="col"> {{ $t('sites.postcode').toUpperCase() }}</th>
        <th scope="col"> {{ $t('sites.cameras').toUpperCase() }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="site in sites" :key="'site-' + site.id" class="cur_pointer">
        <td @click="loadSite(site.id)">{{site.name}}</td>
        <td @click="loadSite(site.id)">{{site.reference}}</td>
        <td @click="loadSite(site.id)">{{site.postcode}}</td>
        <td @click="loadSite(site.id)">{{site.camCount}}</td>
      </tr>
    </tbody>
  </table>

  <!-- pagination -->
  <Pagination v-show="!loading" :config="pagination" @search="(page) => loadSites(page, currentParams)"/> 

</template>



<script>
import SiteService from '../../services/site.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import Pagination from '../../components/Pagination.vue';

export default {
  components: {Form, Field, ErrorMessage, Pagination},
  name: 'Sites',
  data() {
    const schema = yup.object().shape({
      name: yup.string(),
    });
    return {
      loading: true,
      sites: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0
      },
      schema: schema,
      currentParams: {},
    }
  },
  mounted() {
    this.loadSites();
  },
  methods: {
    loadSites(page = 1, params = {}) {
      this.loading = true;
      this.currentParams = params;
      SiteService.getSites(page, this.$store.state.auth.user.selectedClient, params).then((response) => {
        this.pagination = response.data.pagination;
        this.sites = response.data.sites;
        this.loading = false;
      });
    },
    loadSite(id) {
      this.$router.push(`/sites/${id}`);
    },
    clearFilter(page = 1, params = {}) {
      // this.loadSites(page, this.$store.state.auth.user.selectedClient, params);
      this.$refs.form.resetForm();
    }
  }
}
</script>