import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class SiteService {
  ///// GET /////
  getSites(page, client, { name = null, postcode = null, count = null }) {
    const params = new URLSearchParams({
      page: page || 1,
      client_id: client,
    });
    if (name !== null) params.append("name", name);
    if (postcode !== null) params.append("postcode", postcode);
    if (count !== null) params.append("count", parseInt(count));
    return axios.get(
      `https://api.varsanpr.com/api/sites?` + params.toString(),
      { headers: authHeader() }
    );
  }

  getSite(site_id) {
    return axios.get(`https://api.varsanpr.com/api/sites/${site_id}`, {
      headers: authHeader(),
    });
  }

  getInstallPics(site_id) {
    return axios.get(`https://api.varsanpr.com/api/sites/${site_id}/install`, {
      headers: authHeader(),
    });
  }

  getSignIns(page, site_id, data) {
    console.log(data);
    const params = new URLSearchParams({
      page: page || 1,
    });
    Object.keys(data).forEach((key) => {
      if (![null, undefined, ""].includes(data[key])) {
        params.append(key, data[key]);
      }
    });
    return axios.get(
      `https://api.varsanpr.com/api/sites/${site_id}/signin?` +
        params.toString(),
      { headers: authHeader() }
    );
  }

  getInvoices(site_id) {
    return {};
    // return axios.get(`${API_URL}site/${site_id}/invoices`, {headers: authHeader()});
  }

  //get site documents
  getPDFAsset(site_id, filename) {
    return axios.get(
      `https://api.varsanpr.com/api/sites/${site_id}/documents/${filename}`,
      {
        responseType: "blob",
        headers: authHeader(),
      }
    );
  }

  getSignage(site_id) {
    return axios.get(`https://api.varsanpr.com/api/sites/${site_id}/signage`, {
      headers: authHeader(),
    });
  }

  getBrands() {
    return axios.get(`https://api.varsanpr.com/api/sites/brands`, {
      headers: authHeader(),
    });
  }

  getSiteMailingList(site_id) {
    return axios.get(`${API_URL}site/${site_id}/mailing`, {
      headers: authHeader(),
    });
  }

  getSiteReference(client_id) {
    return axios.get(`${API_URL}site/reference?client_id=${client_id}`, {
      headers: authHeader(),
    });
  }

  getRedDiesel(site_id, page = 1, params = {}) {
    params.page = page;
    return axios.get(`https://api.varsanpr.com/api/sites/${site_id}/rdiesel`, {
      headers: authHeader(),
      params: params,
    });
  }

  getLostProperty(site_id, page = 1, params = {}) {
    return axios.get(
      `https://api.varsanpr.com/api/sites/${site_id}/property?page=${page}`,
      { headers: authHeader() }
    );
  }

  ///// PUT /////
  createSite(site) {
    return axios.put(`${API_URL}site`, site, { headers: authHeader() });
  }

  uploadInstallPic(site_id, data) {
    return axios.put(
      `https://api.varsanpr.com/api/sites/${site_id}/install`,
      data,
      { headers: authHeader() }
    );
  }

  uploadSignagePic(site_id, data) {
    return axios.put(
      `https://api.varsanpr.com/api/sites/${site_id}/signage`,
      data,
      { headers: authHeader() }
    );
  }

  addNewBrand(site_id, brand_id) {
    return axios.put(
      `https://api.varsanpr.com/api/sites/${site_id}/brands`,
      { brand: brand_id },
      { headers: authHeader() }
    );
  }

  addNewWhitelist(site_id, entries) {
    return axios.put(
      `https://api.varsanpr.com/api/sites/${site_id}/whitelist`,
      { plates: entries },
      { headers: authHeader() }
    );
  }

  addToMailingList(site_id, user_id) {
    return;
    // return axios.put(`${API_URL}site/${site_id}/mailing`, {user_id: user_id}, {headers: authHeader()});
  }

  //upload site document
  uploadFile(site_id, data) {
    return axios.put(
      `https://api.varsanpr.com/api/sites/${site_id}/documents`,
      data,
      { headers: authHeader() }
    );
  }

  ///// PATCH /////
  editSite(site_id, data) {
    return axios.patch(`${API_URL}site/${site_id}`, data, {
      headers: authHeader(),
    });
  }

  updateEvidenceType(site_id, asset_id, type) {
    return axios.patch(
      `${API_URL}site/${site_id}/documents/${asset_id}`,
      { type: type },
      { headers: authHeader() }
    );
  }

  ///// DELETE /////
  //remove brand from site
  removeBrand(site_id, brand_id) {
    return axios.delete(
      `https://api.varsanpr.com/api/sites/${site_id}/brands`,
      {
        data: {
          brand: brand_id,
        },
        headers: authHeader(),
      }
    );
  }

  removeFromMailingList(site_id, user_id) {
    return;
    // return axios.delete(`${API_URL}site/${site_id}/mailing`, {
    //     data: {user_id},
    //     headers: authHeader()
    // });
  }
}

export default new SiteService();
